
import React from 'react'
import { Barra } from './Barra'
import Micro from '../media/micro.png'
import { Footer } from './Footer'

export const Podcast = () => {
  return (
    <>
      <Barra />
      <div className='Podcast'>
        <div className="info">
          <img src={Micro} alt="Micro" />
          <div className='texto'>
            <h3>THE LUNETIC PROJECT #1 <a className='ver' href="https://youtu.be/Ke7UTf23j1Q"> Ver PodCast</a></h3>
            <a> Podcast inicial, una toma de contacto con el podcast y cosas paranormales.</a>
  
          </div>
        </div>

        <div className="info">
          <img src={Micro} alt="Micro" />
          <div className='texto'>
            <h3>THE LUNETIC PROJECT #2 <a className='verDis'> Ver PodCast</a></h3>
            <a>...</a>
  
          </div>
        </div>


        <div className="info">
          <img src={Micro} alt="Micro" />
          <div className='texto'>
            <h3>THE LUNETIC PROJECT #3 <a className='verDis'> Ver PodCast</a></h3>
            <a>...</a>
  
          </div>
        </div>


      </div>
      <Footer />
    </>
  )
}
