
import React from 'react'
import { Barra } from './Barra'

import Lucas from '../media/lucas.png'
import Carla from '../media/carla.png'
import { Footer } from './Footer'

export const SobreNosotros = () => {
  return (
    <>
      <div className='SobreNosotros'>
        <Barra />
        <h1>SOBRE NOSOTROS</h1>
        <p>Somos dos amigos apasionados por el entretenimiento y la divulgación.</p>
        <div className="p_t">
          <div className="perfil">
            <img src={Lucas} alt="Logo" />
            <div className="texto">
              <h2>Hola, soy <span>Lucas</span></h2>
              <p>Me gusta la programación y escuchar música.
                <br />
                Soy programador principalmente de JavaScript pero también programdo con HTML y CSS
              </p>
              <a href="https://www.instagram.com/luc4s._cg/" className='RRSS'><i class="fa-brands fa-instagram"></i> INSTAGRAM</a>
              <a href="https://www.luc4s.dev" className='RRSS'><i class="fa-solid fa-globe"></i> WEB</a>
            </div>
          </div>

          <div className="perfil">
            <img src={Carla} alt="Logo" />
            <div className="texto">
              <h2>Hola, soy <span>Carla</span></h2>
              <p>
                Soy una apasionada del Freestyle, Fútbol, Boxeo y MMA.
                <br />
                En mis tiempos libres me gusta bailar y jugar a videojuegos.
              </p>
              <a href="https://www.instagram.com/carly_207/" className='RRSS'><i class="fa-brands fa-instagram"></i> INSTAGRAM</a>
              <a href="https://www.instagram.com/carly_207/" className='RRSS'><i class="fa-brands fa-twitch"></i> TWITCH</a>
            </div>
          </div>
        </div>
      </div>
      <Footer /></>
  )
}
