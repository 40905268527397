import React from 'react'
import LogoFooter from '../media/logoFooter.png'
export const Footer = () => {
    return (
        <footer class="Footer">
            <div class="grupo-1">
                <div class="box">
                    <figure>
                        <a href="#">
                            <img src={LogoFooter} alt="Logo" />
                        </a>
                    </figure>
                </div>
                <div class="box">
                    <h2>MÁS DATOS</h2>
                    <a id='DatosMas' href="">Contacto</a>
                    <a id='DatosMas' href="https://thelunaticproject.com/podcast">PodCast</a>
                    <a id='DatosMas' href="https://thelunaticproject.com/nosotros">Nosotros</a>
                </div>
                <div class="box">
                    <h2>SIGUEME</h2>
                    <div class="red-social">
                        <a href="https://www.instagram.com/thelunaticproject/" class="fa fa-instagram"></a>
                        <a href="#" class="fa fa-spotify"></a>
                        <a href="https://www.tiktok.com/@thelunaticproject" class="fa-brands fa-tiktok"></a>
                        <a href="https://www.youtube.com/@lunaticproject" class="fa fa-youtube"></a>
                    </div>
                </div>
            </div>
            <div class="grupo-2">
                <small>&copy; 2023 <b>The Lunatic Porject</b> - Todos los Derechos Reservados.</small>
            </div>
        </footer>
    )
}
