import React from 'react';
import ReactDOM from 'react-dom/client';
// import "./App.css";

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App';
import { SobreNosotros } from './componentes/SobreNosotros';
import { Podcast } from './componentes/Podcast';
import { Redes } from './Redes';

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/redes", element: <Redes /> },
  { path: "/nosotros", element: <SobreNosotros /> },
  { path: "/podcast", element: <Podcast /> },
  
  /*{ path: "", element: <PageNotFound /> },
  { path: "*", element: <PageNotFound /> },
  { element: <PageNotFound /> }, */

])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
