import React from 'react'
import Logo from './media/logo.png'
import LogoFooter from './media/logoFooter.png'

import './Redes.css'
import { Barra } from './componentes/Barra'
import { Footer } from './componentes/Footer'

export const Redes = () => {
    return (
        <>
            
            <Barra />
            <div className="RedesContenido">
                <h1>THE LUNATIC PROJECT</h1>
                <div className="redes">
                    <div>
                        <div className="red">
                            <a href="https://www.youtube.com/@lunaticproject">YouTube</a>
                        </div>
                        <div className="red">
                            <a href="https://www.instagram.com/thelunaticproject/">Instragram</a>
                        </div>
                        <div className="red">
                            <a href="https://twitter.com/LunaticProject_">Twitter</a>
                        </div>
                        <div className="red">
                            <a href="https://www.tiktok.com/@thelunaticproject">TikTok</a>
                        </div>
                        <div className="red">
                            <a href="">Discord</a>
                        </div>
                        <div className="red">
                            <a href="">Twitch</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
