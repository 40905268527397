import React from 'react'
import Logo from '../media/logo.png'

export const Contenido = () => {
    return (
        <div className='Contenido'>
            <div className="c">
                <img src={Logo} alt="Logo" />
                <div className="texto">
                    <h1>The Lunatic Project</h1>
                    <h3>Un podcast de entretenimiento</h3><br />
                    <h4><a href="https://www.youtube.com/@lunaticproject">Canal de YouTube</a> <a href="https://www.instagram.com/thelunaticproject/">Ver Más</a></h4>
                </div>
            </div>
        </div>
    )
}
